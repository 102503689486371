import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Delhi Public School, Vasant Kunj",
  address: "Vasant Kunj, New Delhi",
  client: "DPS Society, New Delhi",
  area: "80,000 Sq. Ft.",
  project: "Auditorium cum Swimming Pool Complex at DPS Vasant Kunj, New Delhi",
  category: "Institutions | Architecture | School",
  status: "Under Construction",
  backlink: "/projects/institutions/",
};

export default class Projectdpsvasantkunj extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Institutional Projects | Delhi Public School, Vasant Kunj"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore site-plans and architectural details of the stunning auditorium cum swimming pool complex at a renowned DPS school in Delhi."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
